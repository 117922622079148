
<template>
<div style="overflow: auto;height:100%" class="bg">
    <img src="../../assets/nyimg/dl2.png" alt="" style="width: 90%;margin:10vh 5% 0; ">
    <div class="msg">
<p>
<span class="username">
   <img src="../../assets/nyimg/dl3.png" alt="">
</span>
<input type="text" placeholder="请输入用户名" v-model="username">
</p>

<p>
<span class="password">
    <img src="../../assets/nyimg/dl4.png" alt="">
</span>
<input type="text" placeholder="请输入密码" v-model="password">
</p>
<p class="btn" @click="login">登录</p>
    </div>
</div>
</template>

<script>
import router from '../../router'
export default {
  name: '',
  components: {},
  data () {
    return {
        username:'',
        password:'',
    }
  },
  created () { },
  mounted () { },
  methods: { 
    login(){
        console.log('login');
        router.push('/nongye')
    }
  },
  computed: {}
}
</script>
<style scoped lang='scss'>
.logo{
    width:10vh;
    height:10vh;
background: url('~@/assets/nyimg/dl2.png');
}
.title{
    width:40vh;
    height:15vh;
    margin:3vh auto 0 ;
    border:1px solid #000
}
.msg{
    width:100%;
    padding:50px;
    box-sizing: border-box;
    p{
         display: flex;
    justify-content:center;
    height: 5vh;
    span{
        width: 20%;
        height:100%;
        border-radius: 2.5vh 0 0 2.5vh;
        background-color:rgba(0,0,0,0.6);
        text-align: center;
        line-height: 5vh;
        margin-right:2px;
       display: flex;
       align-items:center;
       justify-content: center;
            img{
                height:50%;

            }
        
    }
    input{
        width: 80%;
        height:100%;
        box-sizing: border-box;
        padding: 8px 10px ;
        background-color:rgba(0,0,0,0.6);
        border-radius:0 2.5vh 2.5vh 0;
        border:none;
       
    }
    }
   .btn{
    background-color:rgb(38,137,239);
    border-radius:2.5vh;
    line-height: 5vh;
    margin-top:35px
   }

}
.bg{
    background: url('~@/assets/nyimg/dl1.jpg');
    background-size:100% 100%;
}
</style>
