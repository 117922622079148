// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/nyimg/dl2.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/nyimg/dl1.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".logo[data-v-847dbb1a]{width:10vh;height:10vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.title[data-v-847dbb1a]{width:40vh;height:15vh;margin:3vh auto 0;border:.00133rem solid #000}.msg[data-v-847dbb1a]{width:100%;padding:.06667rem;box-sizing:border-box}.msg p[data-v-847dbb1a]{height:5vh}.msg p[data-v-847dbb1a],.msg p span[data-v-847dbb1a]{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center}.msg p span[data-v-847dbb1a]{width:20%;height:100%;border-radius:2.5vh 0 0 2.5vh;background-color:rgba(0,0,0,.6);text-align:center;line-height:5vh;margin-right:.00267rem;-webkit-box-align:center;-webkit-align-items:center;align-items:center}.msg p span img[data-v-847dbb1a]{height:50%}.msg p input[data-v-847dbb1a]{width:80%;height:100%;box-sizing:border-box;padding:.01067rem .01333rem;background-color:rgba(0,0,0,.6);border-radius:0 2.5vh 2.5vh 0;border:none}.msg .btn[data-v-847dbb1a]{background-color:#2689ef;border-radius:2.5vh;line-height:5vh;margin-top:.04667rem}.bg[data-v-847dbb1a]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%}", ""]);
// Exports
module.exports = exports;
